<template>
  <v-dialog persistent v-model="active" :width="screenWidth + '%'">
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.acceptorder.textTitle }}</p>
      <div class="display-flex align-items-center justify-content-center">
        <img width="80px" src="@/assets/images/success.png" alt="" />
      </div>
      <p class="text-description mon-regular" v-html="texts.acceptorder.description" />
      <div class="mt-9">
        <div class="content-input-currency">
          <p @click="setFocusInputCurrency" v-if="costFlete === null || costFlete === ''" class="txt-placeholder-input-currency">
            0.00
          </p>
          <p class="label-input-curreny mon-regular">
            {{ texts.acceptorder.costFlete }}
          </p>
          <!-- <InputCurrencyGlobal :modelValue="costFlete" @changeMontoGlobal="changeMontoGlobal" :iIndex="0"
            :flagModal="active" sSuffix="" sPrefix="$" labelGlobal="" :bOutlined="true" :options="optionsCurrency"
            classDynamic="global-inputs mon-regular" /> -->
          <currency-input
          ref="InputCurrency"
            class="input-currency mon-regular"
            maxlength="12"
            v-model="costFlete"
            :distraction-free="true"
            currency="MXN"
            locale="es-MX"
          />
        </div>
        <div class="display-flex align-items-center mt-5">
          <div style="position: relative; width: 100%">
            <p style="background-color: transparent; margin-top: -1px" class="label-input-curreny mon-regular">
              {{
              selectLanguage == "sp"
              ? "Fecha y hora estimada de entrega en frontera"
              : "Estimated date and time border delivery"
              }}
            </p>
            <v-menu ref="bMenuFinal" v-model="bMenuFinal" :close-on-content-click="false" transition="scale-transition"
              offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field outlined color="#000000" dense class="global-inputs mon-regular" readonly
                  v-model="estimatedDatetextField" clearable hide-details append-icon="mdi-calendar"
                  @click:clear="estimatedDate = null" persistent-hint v-on="on"></v-text-field>
              </template>
              <v-date-picker color="#C41F79" v-model="estimatedDate" @change="ChangeFormatEstimatedDateTextField()"
                no-title @input="bMenuFinal = false" :min="actualDate"></v-date-picker>
            </v-menu>
          </div>
          <div style="position: relative; width: 180px;
              margin-top: 5px;
              margin-left: 5px;
            ">
            <v-menu ref="menu1" v-model="time1" :close-on-content-click="false" :nudge-right="40"
              :return-value.sync="estimatedHour" transition="scale-transition" offset-y max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="estimatedHour" readonly v-bind="attrs" v-on="on" dense outlined color="#000000"
                  class="global-inputs mon-regular" persistent-placeholder placeholder="00:00" suffix="24 Hrs">
                </v-text-field>
              </template>
              <v-time-picker v-if="time1" v-model="estimatedHour" full-width
                @click:minute="$refs.menu1.save(estimatedHour)" format="24hr" header-color="#151d27" color="#151d27">
              </v-time-picker>
            </v-menu>
          </div>
        </div>
      </div>
      <div class="display-flex align-items-center mt-9">
        <v-btn class="button-secondary mon-regular" elevation="0" style="width: 45%" @click="closeDialog">
          {{ texts.acceptorder.buttons.cancel }}
        </v-btn>
        <v-spacer />
        <v-btn class="button-primary mon-medium" elevation="0" style="width: 45%" :loading="bLoading" @click="
          confirmOrder();
          dateOrder();
        " :disabled="!validateForm">
          {{ texts.acceptorder.buttons.confirm }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "OrderDetailAcceptOrderLayout",
  data() {
    return {
      texts: "",
      costFlete: null,
      screenWidth: 0,
      bLoading: false,
      bMenuFinal: false,
      time1: null,
      estimatedHour: "",
      estimatedDate: "",
      estimatedDatetextField: "",
      actualDate: "",
      optionsCurrency: {
        locale: "en-US",
        currency: "USD",
        currencyDisplay: "hidden",
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        autoSign: true,
        useGrouping: true,
        accountingSign: false,
      },
    };
  },
  beforeMount() {
    this.texts = FILE.acceptorder[this.selectLanguage];
    this.closeDialog();
    this.getActualDate();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    changeMontoGlobal(costFlete) {
      if(costFlete === ""){
        this.costFlete = null;
      }else{
        this.costFlete = parseFloat(costFlete);
      }
    },
    setFocusInputCurrency(){
      this.$refs.InputCurrency.$el.focus()
    },
    getActualDate: function () {
      var today = new Date();
      this.actualDate = today.toISOString().substring(0, 10);
    },
    closeDialog: function () {
      this.$store.commit("setDialogAcceptOrder", {
        active: false,
        purchaseOrderId: "",
        idSalesOrder: "",
      });
      this.costFlete = null;
      this.estimatedDate = "";
      this.estimatedDatetextField = "";
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 35;
      } else {
        this.screenWidth = 100;
      }
    },
    // Fecha de flete
    dateOrder: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      },
        payload = {
          tArrivalAtBorderWarehouse:
            this.estimatedHour + " " + this.estimatedDate,
        };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.purchaseOrderId}/arrival`,
        payload,
        config
      )
        .then((response) => { })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    //Id del fee del flete
    confirmOrder: function () {
      this.bLoading = true;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      },
        payload = {};

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/fees/shipping-mexico`,
        config,
        payload
      )
        .then((response) => {
          this.updateCostFlete(response.data.results.sFeeId);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    //Monto de flete
    updateCostFlete: function (id) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      },
        payload = {
          dBaseAmount: this.costFlete,
        };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.salesOrderId}/fees/${id}`,
        payload,
        config
      )
        .then((response) => {
          this.changeStatusOrder();
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },

    changeStatusOrder: function () {
      const payload = {
        sDescription: "N/A",
        sPurchaseOrderStatusId: "37a8cdd3-ef55-4bd5-a310-9bea096ffc15", //en preparación
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/status/purchase-orders/${this.purchaseOrderId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          // this.$router.push("/orders");
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    ChangeFormatEstimatedDateTextField() {
      let aDate = this.estimatedDate.split("-");

      let day = aDate[2];
      let month = aDate[1];
      let year = aDate[0];
      let date = month + "/" + day + "/" + year;
      this.estimatedDatetextField = date;
    },
  },
  computed: {
    // return create contact dialog state
    active() {
      return this.$store.state.acceptOrderActive;
    },
    purchaseOrderId: function () {
      return this.$store.state.acceptPurchaseOrderId;
    },
    salesOrderId: function () {
      return this.$store.state.acceptSalesOrderId;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.costFlete !== 0 &&
        this.costFlete !== "" &&
        this.costFlete !== null &&
        this.estimatedHour !== "" &&
        this.estimatedHour !== null &&
        this.estimatedDate !== ""
      );
    },
  },
  watch: {
    active() {
      if (this.active) {
        this.texts = FILE.acceptorder[this.selectLanguage];
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.acceptorder[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>

.content-input-currency{
  position: relative;
}
.txt-placeholder-input-currency{
  position: absolute;
  top: 25%;
  right: 15px;
  font-size: 14px;
  color: #D8D8D8;
  letter-spacing: normal;
  font-family: "montserrat-regular", sans-serif;
}
.label-input-curreny {
  position: absolute;
  background-color: #ffffff;
  top: -13px;
  left: 12px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: normal;
  width: 320px;
}

.input-currency {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  font-size: 13px;
  width: 100%;
  border: 1px solid #e5e5e5;
  color: #000000;
  letter-spacing: 0px;
  padding: 10px 12px;
}

.input-currency:focus-visible {
  outline: none;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 20px;
}

.text-description {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}
</style>